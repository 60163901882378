export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://f2lfwiim69.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://808sdy2p94.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://6kt92mftva.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://mozy8fsqd0.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://ssji39fksg.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://lkcpdn10kd.execute-api.eu-west-1.amazonaws.com/prod',
};
